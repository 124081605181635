import React from 'react';
import Card from 'react-bootstrap/Card';
import { Amplify } from 'aws-amplify';
import awsconfig from './../aws-exports';
import '@aws-amplify/ui/dist/styles.css';
import { PubSub, Auth } from 'aws-amplify';


Amplify.configure(awsconfig);

// Get chip ID from cognito user attribute and generate topic to subscribe to
async function getUserInfo() {
  const user = await Auth.currentAuthenticatedUser();
  let dataTopic = "lzysmk/" + user.attributes['custom:espChipId'] + "/dt"
  return dataTopic
}


   class Sensors extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          sensorMsg: '{"null": 0}'
        };
    }

    async componentDidMount(){
        await getUserInfo().then(value => {PubSub.subscribe(value).subscribe({
          next: data => {
            try{
              this.setState({ sensorMsg: data.value });
            }
            catch (error){
              console.log("Error, are you sending the correct data?");
            }
          },
          error: error => console.error(error),
          close: () => console.log('Done'),
        });
      })}

    convertEpochTime(epochTime){
      const date = new Date(epochTime * 1000);

      // Get local date and time components
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');

      // Array of month names
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const monthIndex = date.getMonth();
      const month = monthNames[monthIndex];

      // Format local date time
      const localDateTime = `${hours}:${minutes}:${seconds} ${day} ${month}`;
      return localDateTime;
    }

    render() {
      const { sensorMsg } = this.state;
      let sensorData = Math.round(sensorMsg[this.props.name] * 10 ** 1) / 10 ** 1;
      if (Number.isNaN(sensorData)) {
        sensorData = "Waiting for data";
      }
      let sensorTarget = sensorMsg[this.props.target] 
      let epoch = sensorMsg['epoch1'];
      let lastUpdate = '';
      if (epoch) {
        lastUpdate = this.convertEpochTime(epoch);
      } else {
        lastUpdate = 'N/A'; // If the last update time is not available, display "N/A"
      }
  
      if (this.props.name === 'epoch1') {
        return (
          <div className="Sensor">
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title>{this.props.title}</Card.Title>
                <Card.Text>
                  {lastUpdate}
                </Card.Text>
              </Card.Body>
            </Card>
            <style jsx="true">
              {`
                .Sensor {
                  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
                  transition: 0.3s;
                }
                      
                .Sensor:hover {
                  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
                }
              `}
            </style>
          </div>
        );
      }
  
      if (this.props.unit !== "°C") {
        return (
          <div className="Sensor">
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title>{this.props.title}</Card.Title>
                <Card.Text>
                  {sensorData} {this.props.unit}
                </Card.Text>
              </Card.Body>
            </Card>
            <style jsx="true">
              {`
                .Sensor {
                  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
                  transition: 0.3s;
                }
                      
                .Sensor:hover {
                  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
                }
              `}
            </style>
          </div>
        );
      }
  
      return (
        <div className="Sensor">
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>{this.props.title}</Card.Title>
              <Card.Text>
                {sensorData} {this.props.unit} {" --> "}
                {sensorTarget} {this.props.unit}
              </Card.Text>
            </Card.Body>
          </Card>
          <style jsx="true">
            {`
              .Sensor {
                box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
                transition: 0.3s;
              }
                      
              .Sensor:hover {
                box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
              }
            `}
          </style>
        </div>
      );
    }
  }
  
  export default Sensors;
