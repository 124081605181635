import React, { useEffect, useState } from 'react';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'

import Sensors from './components/sensorData';

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react'; // or 'aws-amplify-react-native';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
//import '@aws-amplify/ui/dist/styles.css';
import '@aws-amplify/ui-react/styles.css';
import { Auth } from 'aws-amplify';

Amplify.configure(awsconfig);

//Print creds of current user to console log -remove from prod version
Auth.currentCredentials().then(creds => console.log(creds));

// Pass signout function to App component


// Apply plugin with configuration adn subscribe to MQTT endpoint
Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: 'eu-west-1',
  aws_pubsub_endpoint: 'wss://a2gzi9hmh10yhc-ats.iot.eu-west-1.amazonaws.com/mqtt', //LAZYSMOKE ACCOUNT ENDPOINT
}));

const initialState = { name: '', description: '' }

const App = ( {signOut, user} ) => {
  const [formState, setFormState] = useState(initialState)

  return (
    <div style={styles.container}>
    <Heading level={1}>Hey {user.attributes['email']}</Heading>
    <Button onClick={signOut}>Sign out</Button>
    <h2>LazySmoke v2</h2>
    <div className="App">
      <Container className="p-4">
        <Row className="p-3 justify-content-md-center">
          <Col md="auto"> <Sensors title="Time of last update" name="epoch1" lastUpdate={formState.lastUpdate} /> </Col>
          <Col md="auto"> <Sensors title="BBQ Temperature" name="temp1" unit="°C" target="target1"/> </Col>
          <Col md="auto"> <Sensors title="BBQ Temp 2" name="temp2" unit="°C" target="target2"/> </Col>
          <Col md="auto"> <Sensors title="Pulled Pork 1" name="temp3" unit="°C" target="target3"/> </Col>
          <Col md="auto"> <Sensors title="Pulled Pork 1" name="temp4" unit="°C" target="target4"/> </Col>
          <Col md="auto"> <Sensors title="Pulled Pork 2" name="temp5" unit="°C" target="target5"/> </Col>
          <Col md="auto"> <Sensors title="Pulled Pork 2" name="temp6" unit="°C" target="target6"/> </Col>
          <Col md="auto"> <Sensors title="Beef Brisket 1" name="temp7" unit="°C" target="target7"/> </Col>
          <Col md="auto"> <Sensors title="Beef Brisket 2" name="temp8" unit="°C" target="target8"/> </Col>
          <Col md="auto"> <Sensors title="Battery" name="voltofbatt" unit="V"/> </Col>
          <Col md="auto"> <Sensors title="Pressure" name="bmepressure" unit="mB"/> </Col>
          <Col md="auto"> <Sensors title="Humidity" name="bmehumidity" unit="%"/> </Col>
        </Row>
      </Container>
    </div>
    </div>
  );
}

const styles = {
  container: { width: 1200, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
  todo: {  marginBottom: 15 },
  input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  todoName: { fontSize: 20, fontWeight: 'bold' },
  todoDescription: { marginBottom: 0 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

export default withAuthenticator(App, true);
